import React from 'react'
import {Container} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import RequestProductForm from '../components/Forms/requestProduct'
import * as styles from './styles/pages.module.css'

const RequestProductPage = ({location}) => {
	return (
		<Layout location={location}>
			<Seo title={"Request Product"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<div className={styles.centerContent}>
					<RequestProductForm location={location}/>
				</div>
			</Container>
		</Layout>
	)
}

export default RequestProductPage